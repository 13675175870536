export enum AccountType {
  Quester = "quester",
  Business = "business",
}

export enum Skills {
  // 1) House Chores (7)
  HouseCleaning = "houseCleaning",
  Laundry = "laundry",
  DishWashing = "dishWashing",
  WindowCleaning = "windowCleaning",
  GarbageRemoval = "garbageRemoval",
  CarWashing = "carWashing",
  CarpetCleaning = "carpetCleaning",

  // 2) Maintenance & Repairs (8)
  FurnitureAssembly = "furnitureAssembly",
  Handyman = "handyman",
  MinorElectrical = "minorElectrical",
  MinorPlumbing = "minorPlumbing",
  ApplianceRepair = "applianceRepair",
  HvacMaintenance = "hvacMaintenance",
  PaintingWalls = "paintingWalls",
  YardWork = "yardWork",

  // 3) Moving & Delivery (8)
  HeavyLifting = "heavyLifting",
  MovingHelp = "movingHelp",
  CourierService = "courierService",
  FoodDelivery = "foodDelivery",
  GroceryPickup = "groceryPickup",
  FurnitureDelivery = "furnitureDelivery",
  BicycleMessenger = "bicycleMessenger",
  ErrandRunning = "errandRunning",

  // 4) Child & Pet Care (8)
  Babysitting = "babysitting",
  Nannying = "nannying",
  HomeworkHelp = "homeworkHelp",
  PetWalking = "petWalking",
  PetSitting = "petSitting",
  PetGrooming = "petGrooming",
  HouseSitting = "houseSitting",
  SeniorCheckIns = "seniorCheckIns",

  // 5) Hospitality & Events (8)
  Waitstaff = "waitstaff",
  Bartending = "bartending",
  EventSetup = "eventSetup",
  CateringAssistant = "cateringAssistant",
  Ushering = "ushering",
  TicketScanning = "ticketScanning",
  CoatCheck = "coatCheck",
  PartyCleanup = "partyCleanup",

  // 6) Outdoor & Seasonal (6)
  SnowShoveling = "snowShoveling",
  LawnMowing = "lawnMowing",
  LeafRaking = "leafRaking",
  GardenWeeding = "gardenWeeding",
  HolidayDecorating = "holidayDecorating",
  MinorLandscaping = "minorLandscaping",

  // 7) Promotion & Street Marketing (5)
  FlyerDistribution = "flyerDistribution",
  BrandAmbassador = "brandAmbassador",
  MysteryShopping = "mysteryShopping",
  StreetSurveys = "streetSurveys",
  MascotDressing = "mascotDressing",
}

export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
  PreferNotToSay = "preferNotToSay",
}

export enum EmploymentStatus {
  Student = "student",
  Employed = "employed",
  SelfEmployed = "selfEmployed",
  Unemployed = "unemployed",
  Other = "other",
}
