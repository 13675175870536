import { lazy, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useLocation } from "react-router-dom";
import { firebaseAuth } from "./firebaseConfig";
import { useUserData } from "@hooks/useUserData";
import { LoadingPage } from "@components/LoadingPage";
import { useNavigationHelper } from "@hooks/useNavigationHelper";
import { AccountType } from "backend/models/user/userTypes";
import { trpc } from "./trpc";
import { message } from "antd";
import { useQueryParam } from "use-query-params";
import { useTranslate } from "@tolgee/react";
import { ExternalRoute } from "@components/ExternalRoute";
import Cookies from "js-cookie";
import { getCookieDomain } from "./utils/getCookieDomain";

const AuthRouter = lazy(() => import("./pages/auth/AuthRouter"));

const QuesterRouter = lazy(() => import("./pages/quester/QuesterRouter"));
const BusinessRouter = lazy(() => import("./pages/business/BusinessRouter"));

let savingAccessToken = false;

export const MainRouter = () => {
  const [user, userLoading] = useAuthState(firebaseAuth);
  const { userData, userDataLoading, fetchUserData, setupInProgress, logout } =
    useUserData();

  const { landingUrl } = useNavigationHelper();

  const { t } = useTranslate("mainRouter");
  const [code] = useQueryParam<string>("code");

  const location = useLocation();

  const { mutateAsync: getAccessToken } =
    trpc.paypal.getAccessToken.useMutation();

  useEffect(() => {
    if (user && code && !savingAccessToken) {
      savingAccessToken = true;
      getAccessToken({ code }).then(() => {
        message.success(t("paypalConnected", "Paypal Connected"));
      });
    }
  }, [code, user, getAccessToken, t]);

  useEffect(() => {
    if (userData) {
      Cookies.set("userLoggedIn", "true", {
        domain: getCookieDomain(),
        secure: true,
        sameSite: "strict",
      });
    } else {
      Cookies.remove("userLoggedIn", { domain: getCookieDomain() });
    }
  }, [userData]);

  useEffect(() => {
    if (location.pathname.startsWith("/auth/quester")) {
      window.$crisp?.do?.("chat:hide");
    } else {
      window.$crisp?.do?.("chat:show");
    }
  }, [location]);

  useEffect(() => {
    if (userLoading || userData) return;

    const initUser = async () => {
      try {
        const userData = await fetchUserData();
        if (!userData) {
          // user just reloaded the page during registration (phone number passed)
          logout();
        }
      } catch (e) {
        if (e instanceof Error && e.message === "alreadyFetching") {
          return;
        }

        console.error(e);
      }
    };

    if (user && !userData && !userDataLoading) {
      initUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]);

  const isOnNoAuthPage =
    location.pathname === "/" ||
    location.pathname.startsWith("/auth") ||
    location.pathname.startsWith("/quest");

  if (
    !user &&
    !userLoading &&
    !userData &&
    !userDataLoading &&
    !isOnNoAuthPage
  ) {
    return <ExternalRoute path={landingUrl} />;
  }

  if (
    ((user && !userData) || userLoading || userDataLoading) &&
    !setupInProgress
  ) {
    return <LoadingPage />;
  }

  if (userData) {
    if (userData.accountType === AccountType.Business) {
      return <BusinessRouter />;
    } else {
      return <QuesterRouter />;
    }
  }

  return (
    <Routes>
      <Route path="auth/*" element={<AuthRouter />} />
      <Route path="quest/:questId/*" element={<QuesterRouter />} />
      <Route path="*" element={<ExternalRoute path={landingUrl} />} />
    </Routes>
  );
};
