import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";

// Define color maps
const colorMap: Record<string, string> = {
  success: "#2ecc71",
  warning: "#f39c12",
  info: "#3498db",
  primary: "#9b59b6",
  default: "#1abc9c",
  danger: "#e74c3c", // Added danger color
};

const hoverColorMap: Record<string, string> = {
  success: "#27ae60",
  warning: "#e67e22",
  info: "#2980b9",
  primary: "#8e44ad",
  default: "#16a085",
  danger: "#c0392b", // Added danger hover color
};

const shadowColorMap: Record<string, string> = {
  success: "#1e8449",
  warning: "#d35400",
  info: "#2471a3",
  primary: "#7d3c98",
  default: "#148f77",
  danger: "#a93226", // Added danger shadow color
};

// Styled button using transient prop $btntype
const StyledButton = styled.button<{ $btntype: string; disabled?: boolean; }>`
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({ disabled, $btntype }) =>
    disabled ? "#bdc3c7" : colorMap[$btntype]};
  color: white;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.5rem;
  border: 2px solid #2c3e50;
  box-shadow: ${({ disabled, $btntype }) =>
    disabled ? "0 5px #7f8c8d" : `0 5px ${shadowColorMap[$btntype]}`};
  transition: all 0.2s ease-in-out;
  transform: translateY(0);

  &:hover {
    background-color: ${({ disabled, $btntype }) =>
      disabled ? "#bdc3c7" : hoverColorMap[$btntype]};
    transform: ${({ disabled }) =>
      disabled ? "translateY(0)" : "translateY(-2px)"};
    box-shadow: ${({ disabled, $btntype }) =>
      disabled ? "0 5px #7f8c8d" : `0 7px ${shadowColorMap[$btntype]}`};
  }

  &:active {
    transform: ${({ disabled }) =>
      disabled ? "translateY(0)" : "translateY(5px)"};
    box-shadow: ${({ disabled, $btntype }) =>
      disabled ? "0 5px #7f8c8d" : `0 2px ${shadowColorMap[$btntype]}`};
  }

  &:disabled {
    pointer-events: none;
  }
`;

const Button: React.FC<{
  type?: "success" | "warning" | "info" | "primary" | "default" | "danger";
  children: React.ReactNode;
  onClick?: () => void;
  htmlType?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean | number;
  loading?: boolean;
}> = ({
  type = "default",
  children,
  onClick,
  htmlType,
  className,
  disabled,
  loading
}) => {
  return (
    <StyledButton
      $btntype={type} // Use transient prop here
      onClick={onClick}
      type={htmlType}
      className={classNames(className, "font-title")}
      disabled={!!disabled || loading}
    >
      {loading && <LoadingOutlined className="mr-2" />}
      {children}
    </StyledButton>
  );
};

// No other way to do this
const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  button {
    flex: 1;
  }
`;

export const StyledButtonGroup = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => <ButtonGroup className={className}>{children}</ButtonGroup>;

export { Button as StyledButton };
